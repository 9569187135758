import { Injectable } from '@angular/core';
import { AppConstants } from '../../constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  _baseURL: string;

  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.baseURL;
  }

  loginUser(email: string, password: string): Observable<any> {
    const url = `${this._baseURL}/login`;
    return this.http.post(url, { email, password }).pipe(map((data) => data));
  }

  setUser(user) {
    let user_string = JSON.stringify(user);
    localStorage.setItem('current_user', user_string);
  }

  setLogs(logs) {
    let logs_string = JSON.stringify(logs);
    localStorage.setItem('current_logs', logs_string);
  }

  getLogs() {
    return localStorage.getItem('current_logs');
  }

  setToken(token) {
    localStorage.setItem('accessToken', 'Bearer ' + token);
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }

  getCurrentUser() {
    let user_string = localStorage.getItem('current_user');
    if (!isNullOrUndefined(user_string)) {
      let user = JSON.parse(user_string);
      return user;
    } else {
      return null;
    }
  }

  logoutUser() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('current_user');
  }

  registerUser(
    email: string,
    password: string,
    password_confirmation: string,
    name: string
  ): Observable<any> {
    const url = `${this._baseURL}/register`;
    const body = {
      email,
      password,
      password_confirmation,
      name,
    };

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }

  changePassword(
    email: string,
    password: string,
    password_confirmation: string,
    token: string
  ): Observable<any> {
    const url = `${this._baseURL}/user/changepassword`;
    const body = {
      email,
      password,
      password_confirmation,
      token,
    };

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: 'application/json',
        }),
      })
      .pipe(map((data) => data));
  }
}
