import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CallsService } from 'src/app/calls/calls.service';
import { AppConstants } from 'src/constants';
import { User } from '../../models/user';
import { AuthService } from '../auth.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public user: User;
  public loginError = '';
  public status: boolean;
  public cargando: boolean;
  public isLogged: boolean;
  public dataFake: any;
  public instructions: boolean;

  loading = false;
  feedback = '';
  activate = null;

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private authService: AuthService,
    private callsService: CallsService,
    private translate: TranslateService
  ) {
    this.user = new User('', '');
    this.isLogged = false;
    this.cargando = true
    this.instructions = !!this._route.snapshot.queryParamMap.get('showInstructions')
  }

  ngOnInit() {
    return this.authService
      .loginUser('parra548@gmail.com', 'vuz7CVH2cgb6wtq*zkb')
      .subscribe(
        (response: any) => {
          this.isLogged = true;
          this.cargando = false;
          this.loginError = '';

          this.authService.setUser(response.userData);
          this.authService.setToken(response.token);
          this.authService.setLogs(response.calllog);
        },
        (error) => {
          console.log(error);
          this.loginError = 'Invalid user/password';
        }
      );
    if (this.authService.getCurrentUser()) {
      this.isLogged = true;
    }

    if (window.location.href.includes('activate')) {
      this.activate = 'Your account has been verified';
      window.history.replaceState({}, document.title, 'login');
    }
  }

  clearLoginErrorMessage() {
    this.loginError = null;
  }

  onSubmit(loginForm) {
    this.authService
      .loginUser(this.user.email.toLowerCase(), this.user.password)
      .subscribe(
        (response: any) => {
          this.isLogged = true;
          this.loginError = '';

          this.authService.setUser(response.userData);
          this.authService.setToken(response.token);
          this.authService.setLogs(response.calllog);
        },
        (error) => {
          console.log(error);
          this.loginError = 'Invalid user/password';
        }
      );
  }

  sendEmail(email) {
    var url = AppConstants.baseURL + '/user/recover?email=' + email;
    this.feedback = '';

    this.loading = true;
    fetch(url, {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        this.loading = false;
        this.feedback = response.message;

        if (response.message == 'Recover password')
          this.feedback =
            'We have sent you an e-mail to recover your password.';
      })
      .catch((error) => {
        this.loading = false;
        this.feedback = error.message;
      });
  }
}
