<div *ngIf="cargando" class="main">
  <div class="loginPanel" *ngIf="!isLogged">
    <div class="login">
      <img src="./assets/LogoWWTC-01.svg" class="mb-1" alt="" />
      <div class="mb-2 d-flex justify-content-center">
        <h1>{{'loading'|translate}}</h1>
      </div>
    </div>
  </div>
</div>
<div *ngIf="!cargando" class="main">
  <div class="loginPanel" *ngIf="!isLogged">
    <div class="login">
      <img src="./assets/LogoWWTC-01.svg" class="mb-1" alt="" />
      <div class="mb-2 d-flex justify-content-center">
        <h1>Phone Call Analyzer</h1>
      </div>

      <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
        <div class="form-group">
          <input
            #email="ngModel"
            [(ngModel)]="user.email"
            type="email"
            class="form-control"
            name="email"
            placeholder="Email"
            required
            pattern="([a-z0-9._%+-]|[A-Z0-9._%+-])+@([a-z0-9._%+-]|[A-Z0-9._%+-])+\.([a-z]|[A-Z]){2,4}$"
          />
          <small
            *ngIf="!email.valid && email.touched"
            class="invalid-feedback d-block"
          >
            email is not valid
          </small>
        </div>
        <div class="form-group">
          <input
            #password="ngModel"
            [(ngModel)]="user.password"
            type="password"
            class="form-control"
            name="password"
            id="password"
            placeholder="Password"
            required
            (change)="clearLoginErrorMessage()"
          />

          <small
            *ngIf="!password.valid && password.touched"
            class="invalid-feedback d-block"
          >
            password is not valid
          </small>

          <small *ngIf="loginError" class="invalid-feedback d-block">
            {{ loginError }}
          </small>
        </div>
        <div class="mt-4">
          <input
            type="submit"
            value="Login"
            class="btn btn-login pl-5 pr-5"
            [disabled]="loginForm.invalid"
          />
        </div>
      </form>

      <div class="mt-4">
        <a
          href=""
          data-toggle="modal"
          data-target="#Modal"
          data-backdrop="false"
          >Problems logging in?</a
        >
      </div>
      <div class="alert alert-success mt-3" *ngIf="status">
        We have sent you an email to restart your password, please check your
        inbox
      </div>
      <!-- Button trigger modal -->
      <!-- Modal -->
      <div
        class="modal fade"
        id="Modal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Enter your email
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <input
                #email="ngModel"
                [(ngModel)]="user.email"
                type="email"
                class="form-control"
                name="email"
                id="email"
                placeholder="Email"
              />

              <div style="margin-top: 20px; font-size: 80%">
                {{ feedback }}
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                (click)="sendEmail(user.email)"
                *ngIf="!loading"
              >
                Send
              </button>

              <div
                class="spinner-border text-primary"
                role="status"
                *ngIf="loading"
              >
                <span class="sr-only">{{'loading'|translate}}...</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-4">
        <h2>Aren't you</h2>
        <h2>registered yet?</h2>
      </div>
      <div>
        <button
          [routerLink]="['/register']"
          type="button"
          class="btn btn-register pl-5 pr-5 mt-3 w-100"
        >
          Register Now
        </button>
      </div>

      <div class="alert alert-success mt-3" *ngIf="activate">
        Your account has been verified
      </div>
    </div>
  </div>

  <div class="loginPanel" *ngIf="isLogged">
    <app-config-call></app-config-call>
          <div class="alert alert-success mt-3" *ngIf="instructions">
            {{'instructions'|translate}}
          </div>
  </div>

  <div class="logoWWTC">
    <img src="./assets/LogoWWTC.eeaf8b1d.svg" class="mb-1" alt="" />
  </div>
</div>
