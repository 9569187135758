import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConstants } from '../../constants';
import { map } from 'rxjs/internal/operators/map';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class CallsService {
  _baseURL: string;
  public configCall: any = { gender: null, localLang: null, remoteLang: null };
  public localObj: any;
  public remoteObj: any;

  public transcriptMode = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this._baseURL = AppConstants.baseURL;
  }

  getLanguagesNew(): Observable<any> {
    const url = 'https://core.worldwidetechconnections.com/languages';
    return this.http.get(url, {}).pipe(map((data) => data));
  }

  setConfig(gender, localLang, remoteLang) {
    this.configCall.gender = gender;
    this.configCall.localLang = localLang;
    this.configCall.remoteLang = remoteLang;
  }

  setLocalObj(localObj) {
    this.localObj = localObj;
  }

  setRemoteObj(remoteObj) {
    this.remoteObj = remoteObj;
  }

  getLocalObj() {
    return this.localObj;
  }

  getRemoteObj() {
    return this.remoteObj;
  }
  getTokenTwilio() {
    return this.http.get(
      'https://ginger-finch-1377.twil.io/capability-token'
    );
    return this.http.get('https://ginger-finch-1377.twil.io/capability-token');
  }
  translatev2(audio, sourceLanguageCode, targetLanguageCode) {

    let url = `https://core.worldwidetechconnections.com/services/sts/${sourceLanguageCode}/${targetLanguageCode}`;
    // let url = `https://core.worldwidetechconnections.com/services/sts/es/en`;
    let body = audio;
    return this.http.post(url, body).pipe(map((data) => data));
  }

  getToken() {
    return localStorage.getItem('accessToken');
  }

  saveLog(email, number, time, type): Observable<any> {
    let date = new Date();

    type = type + '';
    time = time + '';

    const url = `${this._baseURL}/savelog`;
    const body = {
      email,
      number,
      time,
      date,
      type,
    };

    return this.http
      .post(url, body, {
        headers: new HttpHeaders({
          Authorization: this.getToken(),
        }),
      })
      .pipe(map((data) => data));
  }

  getLogs(): Observable<any> {
    const url = `${this._baseURL}/catalogos/nacionalidades`;

    return this.http
      .get(url, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Accept: '*/*',
        }),
      })
      .pipe(map((data) => data));
  }

  getUser() {
    return localStorage.getItem('current_user');
  }
}
