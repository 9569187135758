import { CallsService } from './../../calls/calls.service';
import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  SimpleChanges,
  HostListener
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-config-call',
  templateUrl: './config-call.component.html',
  styleUrls: ['./config-call.component.scss'],
})
export class ConfigCallComponent implements OnInit {
  public languages: Array<any>;
  public localLanguage: string;
  public paramLocalLanguage: boolean;
  public remoteLanguage: string;
  public paramRemoteLanguage: boolean;
  public paramNumber: boolean;
  public voice: string;
  public callConfigured: boolean;
  public directCall: boolean;
  time = null;

  @Output() clearLog = new EventEmitter();
  @Output() logOut = new EventEmitter();

  constructor(
    private callService: CallsService,
    private _router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService
  ) {
    this.directCall = !!activatedRoute.snapshot.queryParamMap.get('direct');

    if (activatedRoute.snapshot.queryParams.local) {
      this.localLanguage = activatedRoute.snapshot.queryParamMap.get('local');
      this.paramLocalLanguage = true;
    } else {
      this.localLanguage = 'english-united-states';
      this.paramLocalLanguage = false;
    }

    if (activatedRoute.snapshot.queryParams.remote) {
      this.remoteLanguage = activatedRoute.snapshot.queryParamMap.get('remote');
      this.paramRemoteLanguage = true;
    } else {
      this.remoteLanguage = 'english-united-states';
      this.paramRemoteLanguage = false;
    }

    if (activatedRoute.snapshot.queryParams.number) {
      this.paramNumber = true;
    }

    // language by default
    this.switchLanguage(this.localLanguage)

    this.voice = 'Male';
    this.callConfigured = false;
    this.languages = [];

  }
  ngOnInit(): void {
    this.resetTimer()
    this.callService.getLanguagesNew().subscribe(
      (response) => {
        for (let index = 0; index < response.length; index++) {
          let servicio = response[index].services;
          if (
            servicio.includes('tts') &&
            servicio.includes('stt') &&
            servicio.includes('ttt')
          ) {
            this.languages.push(response[index]);
          }
        }

        let tempArray = this.languages.slice();
        this.languages = this.sortLanguages(tempArray);

        if (this.directCall) {
          setTimeout(() => {
            this.startClient()
          }, 1000);
        }
      },
      (error) => {
        console.log(error);
      }
    );

    if (!this.callService.localObj) {
      this.callConfigured = false;
    } else {
      this.callConfigured = true;
    }
  }

  @HostListener('document:mousemove')
  @HostListener('document:keypress')
  @HostListener('document:click')
  @HostListener('document:wheel')
  resetTimer() {
    if (this.activatedRoute.snapshot.routeConfig.path === 'login') {
      clearTimeout(this.time);
      this.time = setTimeout(() => {
        // idle for 40 seconds
        // back to main menu
        window.location.replace('https://denver.worldwidetechconnections.com/');
      }, 15000);
    }
  }

  switchLanguage(lang: string) {
    if (lang.match(/spanish/i)) {
      return this.translate.use('spanish')
    }

    if (lang.match(/english/i)) {
      return this.translate.use('english')
    }

    if (lang.match(/arabic/i)) {
      return this.translate.use('arabic')
    }

    if (lang.match(/dutch/i)) {
      return this.translate.use('dutch')
    }

    if (lang.match(/bengali/i)) {
      return this.translate.use('bengali')
    }

    if (lang.match(/tamil/i)) {
      return this.translate.use('tamil')
    }

    return this.translate.use(lang)
  }

  onChangeLocal(event) {
    this.callService.setLocalObj(this.getLangByCode(this.localLanguage));
    this.switchLanguage(this.localLanguage)
  }
  onChangeRemote(event) {
    this.callService.setRemoteObj(this.getLangByCode(this.remoteLanguage));
  }

  startClient() {
    setTimeout(() => {
      clearTimeout(this.time)
    }, 500);

    this.callService.setLocalObj(this.getLangByCode(this.localLanguage));
    this.callService.setRemoteObj(this.getLangByCode(this.remoteLanguage));
    this._router.navigate(['/calls'], {
      queryParams: {
        local: this.localLanguage,
        remote: this.remoteLanguage,
        number: this.activatedRoute.snapshot.queryParamMap.get('number'),
        showKeypad: !!this.activatedRoute.snapshot.queryParamMap.get(
          'showKeypad'
        )
          ? true
          : undefined,
        autoStart: !!this.activatedRoute.snapshot.queryParamMap.get('autoStart')
          ? true
          : undefined,
      },
    });
  }

  backToMainMenu() {
    window.location.replace('https://denver.worldwidetechconnections.com/')
  }

  selectSex(sex) {
    this.voice = sex;
  }

  getLangByCode(code) {
    return this.languages.find((x) => x.code === code);
  }

  clearMsg() {
    this.clearLog.emit([]);
  }
  logout() {
    this.logOut.emit(true);
    this.callService.localObj = null;
    this.callService.remoteObj = null;
    this.authService.logoutUser();
    window.location.replace('https://denver.worldwidetechconnections.com/');

  }

  sortLanguages(data) {
    let aux = data.slice();
    aux.sort(function (a, b) {
      if (a.name > b.name) {
        return 1;
      }
      if (a.name < b.name) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    const spanishInternational = aux.find(
      (lng) => lng.code === 'spanish-international'
    );
    const englishUsa = aux.find((lng) => lng.code === 'english-united-states');
    const arraySorted = aux.filter(
      (item) =>
        item.code !== 'spanish - international' &&
        item.code !== 'english - united - states'
    );

    arraySorted.unshift(spanishInternational);
    arraySorted.unshift(englishUsa);
    return arraySorted;
  }

  onChange(e) {
    this.callService.transcriptMode.next(e.target.checked);
  }
}
