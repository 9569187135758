import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public user: User;
  public passwordValidator: boolean;
  public status: boolean;
  public emailDuplicated: boolean;
  public errorMessage: string;
  public onRegister: boolean;
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.user = new User('', '');
    this.passwordValidator = true;
    this.status = false;
    this.emailDuplicated = false;
    this.onRegister = false;
  }

  ngOnInit() {}

  /**
   * @param registerForm
   * Receives the form data and validates if the passwords meet the minimum security
   * requirements and makes the request for registration
   */
  onSubmit(registerForm) {
    this.emailDuplicated = false;
    this.onRegister = false;
    if (registerForm.value['password2'] != this.user.password) {
      this.passwordValidator = false;
    } else {
      this._authService
        .registerUser(
          this.user.email,
          this.user.password,
          registerForm.value['password2'],
          this.user.name
        )
        .subscribe(
          (response) => {
            this.onRegister = true;
            if (response.message == 'User Registered') {
              this.status = true;
            }
          },
          (error) => {
            this.onRegister = false;
            /* let message = JSON.parse(error.error);
            if (message["email"]) {
              this.emailDuplicated = true;
              this.errorMessage = message["email"][0];
            } */

            let message = error.error.message;

            if (message == 'User already registered') {
              this.emailDuplicated = true;
              this.errorMessage = message;
            }
          }
        );
    }
  }

  /**
   * Reset error message in password validation
   */
  validatorPassword() {
    this.passwordValidator = true;
  }
}
