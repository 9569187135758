import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from 'src/app/models/user';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-recover',
  templateUrl: './recover.component.html',
  styleUrls: ['./recover.component.scss'],
})
export class RecoverComponent implements OnInit {
  public user: User;
  public passwordValidator: boolean;

  public errorMessage: string;
  public onRegister: boolean;
  public token;
  public message = '';
  public messageError = '';

  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {
    this.user = new User('', '');
    this.passwordValidator = true;
    this.onRegister = false;
  }

  ngOnInit() {
    this._route.paramMap.subscribe((params) => {
      const token = params.get('token');
      const email = params.get('email');
      console.log({ token, email });
      if (!token || !email) this._router.navigate(['/login']);
      this.token = token;
      this.user.email = email;
    });
  }

  onSubmit(registerForm) {
    this.onRegister = false;
    this.message = '';

    if (registerForm.value['password2'] != this.user.password) {
      this.passwordValidator = false;
    } else {
      this._authService
        .changePassword(
          this.user.email,
          this.user.password,
          registerForm.value['password2'],
          this.token
        )
        .subscribe(
          (response) => {
            this.onRegister = true;
            if (response.message == 'Password reset successfully') {
              this.message = response.message;
            }
          },
          (err) => {
            this.messageError = err.message;
          }
        );
    }
  }

  validatorPassword() {
    this.passwordValidator = true;
  }
}
