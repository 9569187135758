import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'pca-angular';

  constructor(public translate: TranslateService) {
    translate.addLangs(['english', 'spanish', 'arabic'])
    translate.setDefaultLang('english')
  }

  switchLanguage(lang: string) {
    this.translate.use(lang)
    if (lang.match(/spanish/i)) {
      return this.translate.use('spanish')
    }

    if (lang.match(/english/i)) {
      return this.translate.use('english')
    }

    if (lang.match(/arabic/i)) {
      return this.translate.use('arabic')
    }

    return this.translate.use(lang)
  }
}
