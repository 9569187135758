<div *ngIf="directCall" class="main">
  <div class="loginPanel" >
    <div class="login">
      <img src="./assets/LogoWWTC-01.svg" class="mb-1" alt="" />
      <div class="mb-2 d-flex justify-content-center">
        <h1>{{'loading'|translate}}</h1>
      </div>
    </div>
  </div>
</div>
<div class="config-call" *ngIf="!directCall">
  <!-- <div>
    <h3>Do you need a voice of a</h3>
    <div class="sex">
      <div (click)="selectSex('Male')" [ngClass]="{ active: voice === 'Male' }">
        <span>Male</span>
      </div>
      <div
        (click)="selectSex('Female')"
        [ngClass]="{ active: voice === 'Female' }"
      >
        <span>Female</span>
      </div>
    </div>
  </div> -->
  <div>
    <h3>{{'localLanguage'|translate}}</h3>
    <select
      name="languges"
      [(ngModel)]="localLanguage"
      class="custom-select"
      (change)="onChangeLocal($event)"
    >
      <option
        *ngFor="let language of languages; let i = index"
        value="{{ language.code }}"
      >
        {{ language.name }}
      </option>
    </select>
  </div>
  <div>
    <h3>{{'remoteLanguage'|translate}}</h3>
    <select
      name="languges"
      [(ngModel)]="remoteLanguage"
      class="custom-select"
      (change)="onChangeRemote($event)"
      [disabled]="!!paramRemoteLanguage && !!paramNumber"
    >
      <option
        *ngFor="let language of languages; let i = index"
        value="{{ language.code }}"
      >
        {{ language.name }}
      </option>
    </select>
  </div>
  <app-button
    *ngIf="!callConfigured"
    [text]="'start'|translate"
    [clase]="'secondary'"
    (click)="startClient()"
  ></app-button>
  <br />
  <app-button
    *ngIf="!callConfigured"
    [text]="'backToMainMenu'|translate"
    [clase]="'outlined'"
    (click)="backToMainMenu()"
  ></app-button>
  <div *ngIf="callConfigured">
    <!-- <div style="font-size: 20px">
      <span>
        <img src="./assets/img/svg/headset.svg" style="width: 2.5rem" alt="" />
        Transcript mode
      </span>
      <label class="switch">
        <input type="checkbox" (change)="onChange($event)" />
        <span class="slider round"></span>
      </label>
    </div> -->
    <div class="botton-buttom">
      <!-- <app-button
        [text]="'Clean messages'"
        [clase]="'info'"
        (click)="clearMsg()"
      ></app-button> -->
      <app-button
        [text]="'backToMainMenu'|translate"
        [clase]="'outlined'"
        (click)="logout()"
      ></app-button>
    </div>
  </div>
</div>
