<div class="main">
  <div class="registerPanel">
    <div class="register">
      <img src="./assets/LogoWWTC-01.svg" class="mb-1" alt="" />
      <div class="mb-2 d-flex justify-content-center">
        <h1>Phone Call Analizer</h1>
      </div>
      <form #registerForm="ngForm" (ngSubmit)="onSubmit(registerForm)">
        <div class="form-group">
          <input
            #password="ngModel"
            [(ngModel)]="user.password"
            type="password"
            class="form-control"
            name="password"
            id="password"
            placeholder="Password*"
            minlength="6"
            required
          />
          <small
            *ngIf="!password.valid && password.touched"
            class="invalid-feedback d-block"
          >
            password is not valid, min 6 characters
          </small>
        </div>
        <div class="form-group">
          <input
            #password2="ngModel"
            ngModel
            type="password"
            class="form-control"
            name="password2"
            (change)="validatorPassword()"
            id="password2"
            placeholder="Confirm Password*"
            required
          />
          <small *ngIf="!passwordValidator" class="invalid-feedback d-block">
            password is not valid
          </small>
        </div>
        <div class="alert alert-success mt-1" *ngIf="message">
          {{ message }}
        </div>
        <div class="alert alert-danger mt-1" *ngIf="messageError">
          {{ messageError }}
        </div>
        <div class="mt-5">
          <input
            type="submit"
            value="Update"
            class="btn btn-success pl-5 pr-5"
            [disabled]="registerForm.invalid"
            [disabled]="onRegister"
          />
        </div>
      </form>

      <div class="mt-3">
        <button
          [routerLink]="['/login']"
          class="btn btn-primary pl-5 pr-5 w-100"
        >
          Back to login
        </button>
      </div>
    </div>
  </div>
  <div class="logoWWTC">
    <img src="./assets/LogoWWTC.eeaf8b1d.svg" class="mb-1" alt="" />
  </div>
</div>
